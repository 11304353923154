import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const UniLogix = () => {
  const headingbanner = {
    title: `Unilogix App`,
    content: `A Logistics Insights Application`,
  };

  const data = {
    images: ["uni-logics.webp", "uni-logics-2.webp"],
    firstAlt: "Mobile UI UX Screen Of Logistics Insight Application",
    firstTitle: "Logistics Services List",
    secondAlt: "Order And Warehouse Details",
    secondTitle: "Order Details",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Logistics"],
      },
      {
        title: "What we did",
        text: [
          "Branding",
          "UI UX Designing",
          "UI Development",
        ],
      },
      {
        title: "Platform",
        text: ["Mobile Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The existing platform lacked real-time performance visibility of the orders leading to inefficiencies in the supply chain operations. It was difficult to utilise the resources which resulted in inadequate fulfilment of the customer's demands.`,
          `Earlier, there was no one platform to track the data and analyse the insights. The users had to look into different platforms for different logistical information and missed continuous opportunities for improvements.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `We provided one-stop solutions for all the logistical needs of the users to analyse and interpret their shipment data. The platform now facilitates the real-time visibility of the data to monitor and modify the supply chain operations.`,
          `Users can proactively manage the platform and mitigate the risk that may hamper customer service. They can also track the orders along with the delivery partner to facilitate seamless communication and effective business processes.`,
        ],
      },
    ],
    image: ["uni-logics-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: "Dashboard",
    para: [
      `The dashboard shows various services provided by the platform for seamless function and improved business efficiency.`,
    ],
    content: {
      imageAlt: 'Mobile Responsive Dashboard to check logistics performance',
      imageTitle: 'Dashboard',
      image: ["uni-logics-projectone.webp"],
      text: `Every service leads to its specific interface that shows particular performance insights. The main goal is to provide a better understanding of the data, hence we made it visually appealing and interactive. `,
    },
  };

  const projectData2 = {
    title: "Detailed Overview",
    para: [
      `Being a logistic platform, it is crucial to show the information in a statical form that is easily understandable by the users. We provided insights into all the services along with the delivery partner information to facilitate better service offerings.`,
    ],
    content: {
      image: ["uni-logics-projecttwo.webp"],
      imageAlt: 'UI Screen to show detailed order report',
      imageTitle: 'Order Overview',
    },
  };

  const projectData3 = {
    title: "Order Status",
    para: [
      `The interface also shows the status of the order along with the specific data insights of deliverables for better clarity and understanding.`,
    ],
    content: {
      image: [
        "uni-logics-projectthree.webp",
        "uni-logics-projectthree-2.webp",
        "uni-logics-projectthree-3.webp",
        "uni-logics-projectthree-4.webp",
        "uni-logics-projectthree-5.webp",
      ],
      firstAlt: 'Mobile UI Screen to check distressed order status',
      firstTitle: 'Distressed Orders',
      secondAlt: 'Mobile Responsive to check exchange order status',
      secondTitle: 'Exchange Status',
      thirdAlt: 'Mobile Responsive of return order status',
      thirdTitle: 'Return Status',
      fourthAlt: 'Mobile Responsive of order fulfillment status',
      fourthTitle: 'Order Fulfillment Status',
      fifthAlt: 'Inbound Inventory Details',
      fifthTitle: 'Inventory Status',
    },
  };

  const conclusionData = [
    {
      para: [
        `This platform caters to the logistical needs of the users by providing specific details of every order.`,
        `Earlier, in order to check the information or gain insights into particular services, users had to navigate through different platforms.`,
        `We eliminated the need for multiple platforms and provided an all-in-one solution that integrates multiple services in one application.`,
        `This digital transformation increased the efficiency of the business and assisted in long-term growth by integrating multiple platforms into a unified system. `,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={16}/>
      </div>
    </Layout>
  );
};

export default UniLogix;

export const Head = () => (
  <>
    <meta name="keywords" content="unilogix, ui ux design, case study, ui ux, project" />
    <Seo title="Uni Logix- Octet Design Studio" description="An app that provides logistical insights to track shipment performance. We provided branding, UI UX Designing and Flutter Development Services." />
  </>
)